import React from "react";
import ReactLoading from "react-loading";
import { autobind } from "react-decoration";
import { ApolloProvider, Query } from "react-apollo";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import { inject, observer } from "mobx-react";
import qs from "query-string";
//import throttle from 'lodash.throttle';
import { withI18next } from "lib/withI18next";
import client from "lib/ApolloClient";
import Pagination from "./pagination";
//import List from './view';
import navigate from "lib/navigate";
import withLocation from "lib/withLocation";
//import DataListHeader from './DataListHeader';
//import DataContent from './DataContent';
//import DataListBatchBar from './DataListBatchBar';
import defaultEvent from "./dataList-default-event";

@inject("readerStore")
@observer
@withLocation
@withI18next(["common"])
class IntegrationDataList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {
        total: 0,
      },
      exportMarcId: "",
      web20: {},
      load: false,
      checkall: false,
      auth: props.readerStore.auth,
      refetch: null,
    };
    this.Query = React.createRef();
    this.permanentLink = React.createRef();
    this.exportBox = React.createRef();
    this.forwardBox = React.createRef();
    this.loadMoreData = debounce(this.loadMoreData, 50);
  }

  componentDidMount() {
    window.dataList = this;
    if (
      this.props.pagination === "FixedPagination" ||
      this.props.pagination === "IntegrationPagination"
    ) {
      window.addEventListener("scroll", this.loadMoreData);
    }
    //First Dom Ready
  }

  componentDidUpdate() {
    //Dom rerender
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.loadMoreData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    window.removeEventListener("scroll", this.loadMoreData);
    if (
      nextProps.pagination === "FixedPagination" ||
      this.props.pagination === "IntegrationPagination"
    ) {
      window.addEventListener("scroll", this.loadMoreData);
    }
    /*
    if (this.state.auth !== nextProps.readerStore.auth) {
      if (this.state.refetch !== null && this.state.refetch !== undefined) {
        this.state.refetch();
      }
      this.setState({ auth: nextProps.readerStore.auth });
    }*/
  }

  @autobind
  replaceParamRedirect(params) {
    let { search, location } = this.props;
    params = { ...search, ...params };
    //params.fetch;
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  removeParamRedirect(removeKeys) {
    let { search, location } = this.props;

    let deletenum = 0;
    let paramskey = Object.keys(search);
    for (let removeKey of removeKeys) {
      if (paramskey.includes(removeKey)) {
        delete search[removeKey];
        deletenum++;
      }
    }
    if (deletenum) {
      navigate([location.pathname, qs.stringify(search)].join("?"));
    }
  }

  @autobind
  removeEmptyAndNullKey(obj) {
    Object.keys(obj).map((key) => {
      if (
        obj[key] === null ||
        obj[key] === undefined ||
        (typeof obj[key] === "string" && obj[key].trim() === "")
      ) {
        delete obj[key];
      }
      return "";
    });
  }

  @autobind
  getFormName() {
    let FormName = "NotForm";
    try {
      FormName = Object.keys(this.props.variables)[0];
    } catch (e) {
      console.error(e);
    }
    return FormName;
  }

  @autobind
  async fetchMoreData(page) {
    const currentQuery = this.Query.current;

    const { fetchMore, data } = currentQuery.getQueryResult();

    let formName = this.getFormName();

    let queryNodeName = currentQuery.operation.name;

    const { showNext, limit, pageNo, hyftdToken, total, iTotal, iFilterTotal } =
      Object.values(data)[0].info;

    let params = qs.parseUrl(window.location.href).query;

    let { filterField, db } = params;
    filterField = filterField || [];
    db = db || [];
    if (typeof filterField === "string") {
      filterField = [filterField];
    }
    if (typeof db === "string") {
      db = [db];
    }
    let filterNonDBNum = filterField.filter((f) => {
      if (f !== "db") {
        return 1;
      }
      return 0;
    });
    let filterDBNum = filterField.filter((f) => {
      if (f === "db") {
        return 1;
      }
      return 0;
    });
    let showLoadMore = false;

    if (
      filterNonDBNum.length === 0 &&
      (db.length === 1 || filterDBNum.length === 1)
    ) {
      if (total < iFilterTotal) showLoadMore = true;
    }

    //本次查詢已抓回的筆數
    //let LoadPageNum = Math.floor(total / limit); // document.querySelectorAll(".showPageNo").length;

    //頁面起始的頁次
    /*let StartPageNo = ((parseInt(params.pageNo) + "").match(/^[0-9]+$/) || [
      1,
    ])[0];*/

    if ((showNext || showLoadMore) && !this.state.load) {
      this.setState({ load: true });

      let fetch = Math.min(total + 30, iTotal);

      let form = {
        ...this.props.variables[formName],
        pageNo: pageNo + 1,
        limit: limit,
        fetch,
        hyftdToken: hyftdToken,
      };

      /**有下一頁不fetch 更多 */
      if (showNext) {
        delete form["fetch"];
      }

      this.removeEmptyAndNullKey(form);

      let needAsyncFetch = false;

      this.setState({ load: true });

      //loadMoreTempNum = 0;

      await fetchMore({
        variables: {
          [formName]: form,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const { process } = fetchMoreResult[queryNodeName].info;
          //const { values } = fetchMoreResult[queryNodeName].list;

          if (process) {
            fetchMoreResult[queryNodeName].info.fetchMoreProcess = true;
            fetchMoreResult[queryNodeName].info.process = false;
            fetchMoreResult[queryNodeName].info.filterPid =
              previousResult[queryNodeName].info.filterPid;
            needAsyncFetch = true;
            fetchMoreResult[queryNodeName].list.values = [];
          } else {
            fetchMoreResult[queryNodeName].info.fetchMoreProcess = false;
          }
          /*
          console.log("startPageNo1 previousResult",previousResult[queryNodeName].list.values.length)
          console.log("startPageNo1 isLoaded",loadMoreTempNum)
          loadMoreTempNum = fetchMoreResult[queryNodeName].list.values.length;          
          console.log("startPageNo1 append",fetchMoreResult[queryNodeName].list.values.length)
          */

          return {
            ...previousResult,
            ...{
              [queryNodeName]: {
                ...fetchMoreResult[queryNodeName],
                list: {
                  ...fetchMoreResult[queryNodeName].list,
                  values: [
                    ...previousResult[queryNodeName].list.values,
                    ...fetchMoreResult[queryNodeName].list.values,
                  ],
                },
                info: {
                  ...fetchMoreResult[queryNodeName].info,
                  start: Math.min(
                    previousResult[queryNodeName].info.start,
                    fetchMoreResult[queryNodeName].info.start
                  ),
                },
              },
            },
          };
        },
      });
      if (needAsyncFetch) {
        setTimeout(() => {
          this.loadAsyncData(formName, form, queryNodeName, fetchMore, page);
        }, 50);
      } else {
        this.setState({ load: false });
      }
    } else {
      console.error("noMore");
    }
  }

  @autobind
  getRandomValue() {
    let randomValuesArray = new Int32Array(1);
    let crypto = window.crypto || window.msCrypto;
    if (crypto) {
      let randomValues = crypto.getRandomValues(randomValuesArray);
      var random = randomValues[0] / (0xffffffff + 1);
      if (random < 0) {
        random = -random;
      }
      return random;
    }
    return 0;
  }

  loadAsyncData = async (formName, form, queryNodeName, fetchMore, page) => {
    let needAsyncFetch = false;
    await fetchMore({
      variables: {
        [formName]: form,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        var l = this.getRandomValue();
        const { process } = fetchMoreResult[queryNodeName].info;
        //const { values } = fetchMoreResult[queryNodeName].list;
        //console.log("startPageNo2 loadAsyncData",start)
        fetchMoreResult[queryNodeName].info.fetchMoreProcess = process;

        if (process) {
          fetchMoreResult[queryNodeName].info.fetchMoreProcess = true;
          fetchMoreResult[queryNodeName].info.process = false;
          fetchMoreResult[queryNodeName].info.filterPid =
            previousResult[queryNodeName].info.filterPid;
          needAsyncFetch = true;
          fetchMoreResult[queryNodeName].list.values = [];
        } else {
          fetchMoreResult[queryNodeName].info.fetchMoreProcess = false;
        }

        /*
        console.log("startPageNo2 previousResult",previousResult[queryNodeName].list.values.length,l)
        console.log("startPageNo2 fetchMoreResult",fetchMoreResult[queryNodeName].list.values.length,l)

        let divnum = loadMoreTempNum>0?previousResult[queryNodeName].list.values.length-loadMoreTempNum:0;
        
        if(divnum>0){
          console.log("startPageNo2 needsubLast",loadMoreTempNum,l)          
          console.log("startPageNo2 previousResultBefore",previousResult[queryNodeName].list.values.length,l)
          previousResult[queryNodeName].list.values.splice(divnum);
          console.log("startPageNo2 previousResultAfter",previousResult[queryNodeName].list.values.length,l)
        }
        console.log("startPageNo2 append",fetchMoreResult[queryNodeName].list.values.length,l)
        loadMoreTempNum = fetchMoreResult[queryNodeName].list.values.length;
        */

        let appendResult = {
          ...previousResult,
          ...{
            [queryNodeName]: {
              ...fetchMoreResult[queryNodeName],
              list: {
                ...fetchMoreResult[queryNodeName].list,
                values: [
                  ...previousResult[queryNodeName].list.values,
                  ...fetchMoreResult[queryNodeName].list.values,
                ],
              },
              info: {
                ...fetchMoreResult[queryNodeName].info,
                start: Math.min(
                  previousResult[queryNodeName].info.start,
                  fetchMoreResult[queryNodeName].info.start
                ),
              },
            },
          },
        };

        console.log(
          "startPageNo2 appendResult",
          appendResult.startIntegrationSearch.list.values.length,
          l
        );
        return appendResult;
      },
    });
    if (needAsyncFetch) {
      setTimeout(() => {
        this.loadAsyncData(formName, form, queryNodeName, fetchMore, page);
      }, 50);
    } else {
      this.setState({ load: false }, () => {
        this.goPage(page);
      });
    }
  };

  @autobind
  loadMoreData() {
    let scrollElement = document.querySelector(".infinitescroll");
    const currentQuery = this.Query.current;
    const { loading } = currentQuery.getQueryResult();
    if (
      !loading &&
      !this.state.load &&
      scrollElement &&
      window.pageYOffset + window.innerHeight >
        scrollElement.getBoundingClientRect().top + window.pageYOffset
    ) {
      this.fetchMoreData();
    }
  }

  goPage = (page, loadauto) => {
    const currentQuery = this.Query.current;
    const { loading, data } = currentQuery.getQueryResult();
    const { start, limit, pageNo } = Object.values(data)[0].info;

    let startPageNo = Math.ceil(start / limit) + 1;

    const index = page - startPageNo;
    const isFixPagination =
      this.props.pagination === "FixedPagination" ||
      this.props.pagination === "IntegrationPagination";

    if ((index > 5 || index === -1) && !loadauto) {
      this.replaceParamRedirect({ pageNo: page, fetch: page * 30 });
      document.querySelector("html, body").scrollTop = 0;
      /*$("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );*/
      return false;
    }

    if (!loading && data !== null && data !== undefined) {
      if (!isFixPagination) {
        this.replaceParamRedirect({ pageNo: page });
        return false;
      }

      let showPages = document.querySelectorAll(".showPageNo");

      if (index > -1 && showPages && showPages.length > index) {
        let toPage = showPages[index];
        document.querySelector("html, body").scrollTop =
          toPage.getBoundingClientRect().top + window.pageYOffset - (55 + 64);
        /*$("html, body").animate(
          {
            scrollTop: toPage.offset().top - 129,
          },
          300
        );*/
      } else {
        /*
        if(document.querySelectorAll(".list_block").length>30&&!loadauto){
          this.replaceParamRedirect({ pageNo: page });
          $("html, body").animate(
            {
              scrollTop: 0,
            },
            0
          );
          return false;
        }*/

        let isLoadedPage = pageNo;
        if (isLoadedPage + 1 === page) {
          const self = this;
          setTimeout(async () => {
            await this.fetchMoreData(page);
            setTimeout(() => {
              self.goPage(page, true);
            }, 50);
          }, 800);
        }
      }
    }
  };

  fetchEvent = (actionName, actionParams, isBatch = false, e) => {
    const { fetchEvent: fetchParentEvent } = this.props;

    let fun = null;
    if (fetchParentEvent !== null && fetchParentEvent !== undefined) {
      fun = fetchParentEvent(actionName);
    }

    if (typeof fun !== "function") {
      fun = defaultEvent[actionName];
      if (typeof fun === "function") {
        fun = fun.bind(this);
      }
    }

    if (typeof fun === "function") {
      if (isBatch) {
        fun(actionParams, e);
      } else {
        fun(...actionParams, e);
      }
    } else {
      console.warn(`function ${actionName} is undefined`);
    }
  };

  checkAll = () => {
    let allCheck = document.querySelectorAll(".selected-data");
    let allChecked = document.querySelectorAll(".selected-data:checked");

    if (allCheck.length !== allChecked.length) {
      allCheck.forEach((e) => {
        e.checked = true;
      });
    } else {
      allCheck.forEach((e) => {
        e.checked = false;
      });
    }
  };

  render() {
    const { displayComp, customComponentLayout } = this.props;
    //const Comp = List[displayComp] || displayComp;
    const Comp = displayComp;
    const CustomLayout = customComponentLayout;

    let compDefaultOption = Comp.defaultOption || {};
    let {
      auth,
      pagination,
      customizeData,
      customizeConfig,
      handleClick,
      onQueryCompleted,
      displayCheckBox,
      cnt,
      getRefetch,
      showNoData,
      hasFilter,
      t,
    } = { ...compDefaultOption, ...this.props };

    const PaginationComp = Pagination[pagination];

    return (
      <>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query {...this.props} ref={this.Query}>
            {({ loading, data, error, refetch }) => {
              if (error) return "";
              if (this.state.refetch === null) {
                this.setState({ refetch: refetch }, () => {
                  getRefetch(refetch);
                });
              }

              if (!loading && data !== null && data !== undefined) {
                const { batch, display, info, list, options } =
                  Object.values(data)[0];
                const { limit, start, process } = info;
                const { values, detail, func, func_left, func_right } = list;
                let startPageNo = Math.ceil(start / limit) + 1;

                onQueryCompleted(data);

                let compDataContent = [];

                if (process && values.length === 0) {
                  return (
                    <div className="booklist_more">
                      <center>
                        <ReactLoading
                          type="cylon"
                          color="#005D98"
                          height={"10"}
                          width={"20%"}
                        />
                      </center>
                    </div>
                  );
                }

                values.map((row, key) => {
                  //let last10 = values.length - key === limit / 2;
                  let last10 = values.length - key === 1;
                  const serialNo = start + key + 1;

                  let options = {
                    displayConfig: display,
                    data: row.ref,
                    resourceData: row.resourceItem,
                    functionConfigList: func || [],
                    functionRightConfigList: func_right || [],
                    functionLeftConfigList: func_left || [],
                    detailList: detail || [],
                    buttonEvent: this.fetchEvent,
                    batchConfig: batch || [],
                    serialNo: serialNo,
                    rowStyle: "",
                    auth: auth,
                    customizeData: customizeData,
                    customizeConfig: customizeConfig,
                    info: info,
                  };

                  let rowStyles = [];
                  if (Comp.listType !== "Table") {
                    if (key % limit === 0)
                      rowStyles.push(
                        `showPageNo ${startPageNo + Math.floor(key / limit)}`
                      );
                    if (last10) {
                      rowStyles.push("infinitescroll");
                    }
                  }
                  options.rowStyle = rowStyles.join(" ");
                  compDataContent.push(
                    <Comp
                      {...this.props}
                      key={`row${key}`}
                      {...options}
                      handleClick={handleClick}
                      displayCheckBox={displayCheckBox}
                      refetch={refetch}
                      cnt={cnt}
                      t={this.props.t}
                      i18n={this.props.i18n}
                      readerStore={this.props.readerStore}
                      variables={this.props.variables}
                      refetchQueryGroup={this.props.refetchQueryGroup}
                      isChecked={this.props.isChecked}
                    />
                  );
                  return "";
                });

                return (
                  <>
                    {/*
                    <div className="data_all">
                      <DataListBatchBar
                        display={showBatchBar}
                        fieldDisplay={display}
                        checkAll={this.checkAll}
                        batch={batch}
                        values={values}
                        refetch={refetch}
                        fetchEvent={this.fetchEvent}
                      />
                      <DataListHeader
                        display={showHeader}
                        sort={sort}
                        showDisp={showDisp}
                        {...info}
                      />
                    </div>
                    

                    <DataContent
                      showFetchMoreButton={showFetchMoreButton}
                      fetchMoreButtonText={fetchMoreButtonText}
                      fetchMoreData={this.fetchMoreData}
                      blockClass={blockClass}
                      title={title}
                      listType={Comp.listType}
                      displayConfig={display}
                      batchConfig={batch}
                    >*/}
                    {customComponentLayout ? (
                      <CustomLayout compDataContent={compDataContent} />
                    ) : (
                      compDataContent
                    )}

                    {compDataContent.length === 0 && showNoData && (
                      <div className="nodata">
                        {t("jumperrwd.common.zeroRecord")}
                      </div>
                    )}
                    {/*</DataContent>
                    {this.state.load && (
                      <div className="booklist_more">
                        <center>Loading...</center>
                      </div>
                    )}     */}
                    {pagination !== "FixedPagination" && PaginationComp && (
                      <PaginationComp
                        goPage={this.goPage}
                        {...options}
                        {...info}
                        startPageNo={startPageNo}
                        hasFilter={hasFilter}
                        t={this.props.t}
                      />
                    )}

                    {this.state.load && (
                      <div tabIndex="0">{t("jumperrwd.common.loading")}</div>
                    )}
                  </>
                );
              }
              return (
                <div className="booklist_more">
                  <center>
                    <ReactLoading
                      type="cylon"
                      color="#005D98"
                      height={"10"}
                      width={"20%"}
                    />
                  </center>
                </div>
              );
            }}
          </Query>
        </ApolloProvider>
      </>
    );
  }
}

const requiredPropsCheck = (props, propName, componentName) => {
  if (
    !(
      typeof props.displayComp === "string" ||
      typeof props.displayComp === "function"
    )
  ) {
    return new Error(
      `One of 'displayComp' or 'children' is required by '${componentName}' component.`
    );
  }
};

/**Fixed:Infinite Scroll*/
IntegrationDataList.defaultProps = {
  blockClass: "booklist_block",
  title: "",
  showFetchMoreButton: false,
  fetchMoreButtonText: "更多資料",
  customizeData: (data) => data,
  customizeConfig: (config) => config,
  limitRangeDisplay: false,
  onQueryCompleted: () => {},
  getRefetch: () => {},
  showNoData: false,
};

IntegrationDataList.propTypes = {
  router: PropTypes.object,
  blockClass: PropTypes.oneOf([
    "booklist_block",
    "booklist_hot_block",
    "devicereservation_block",
  ]),
  pagination: PropTypes.oneOf(["NormalPagination", "FixedPagination"]),
  displayComp: requiredPropsCheck,
  children: requiredPropsCheck,
  showFetchMoreButton: PropTypes.bool,
  fetchMoreButtonText: PropTypes.string,
  showDisp: PropTypes.bool,
  showBatchBar: PropTypes.bool,
  showHeader: PropTypes.bool,
  query: PropTypes.shape({
    definitions: PropTypes.array,
    kind: PropTypes.string,
    loc: PropTypes.object,
  }),
  variables: PropTypes.object,
  title: PropTypes.string,
  fetchEvent: PropTypes.func,
  /** customize list.values.ref  */
  customizeData: PropTypes.func,
  customizeConfig: PropTypes.func,
  /**query grouping trigger element display for moible,pad device */
  limitRangeDisplay: PropTypes.bool,
  /**graphql query callback (values,total)=>{...} */
  onQueryCompleted: PropTypes.func,
  showNoData: PropTypes.bool,
};

export default IntegrationDataList;
