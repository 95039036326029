import React from "react";
import client from "lib/ApolloClient";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import ReactLoading from "react-loading";
//import { encoder as ESAPIEncoder } from "node-esapi";
import Link from "lib/Link";

const searchRelated = gql`
  query searchRelated($form: SearchRelatedForm) {
    searchRelated(Input: $form) {
      cn
      en
    }
  }
`;

class SearchRelatedModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      display: props.visible ? "block" : "none",
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.visible !== this.props.visible) {
      this.setState({ display: props.visible ? "block" : "none" });
    }
  }

  handleUrl = (cn) => {
    let { href = "" } = window.location;
    let url = href;
    //let url = ESAPIEncoder().normalize(href);
    if (url.split("&searchInput=").length > 1)
      url = url.replace(url.split("&searchInput=")[1].split("&")[0], cn); // 處理查詢詞
    if (url.split("&pid=").length > 1)
      url = url.replace("&pid=" + url.split("&pid=")[1].split("&")[0], ""); // 處理PID
    if (url.split("&filterPid=").length > 1)
      url = url.replace(
        "&filterPid=" + url.split("&filterPid=")[1].split("&")[0],
        ""
      ); // 處理FilterPID
    if (url.split("&filterDBID=").length > 1)
      url = url.replace(
        "&filterDBID=" + url.split("&filterDBID=")[1].split("&")[0],
        ""
      ); // 處理FilterDBID
    if (url.split("&filterField=").length > 1)
      url = url.replace(
        "&filterField=" + url.split("&filterField=")[1].split("&")[0],
        ""
      ); // 處理FilterField
    if (url.split("&filterValue=").length > 1)
      url = url.replace(
        "&filterValue=" + url.split("&filterValue=")[1].split("&")[0],
        ""
      ); // 處理FilterValue
    if (url.split("&filterName=").length > 1)
      url = url.replace(
        "&filterName=" + url.split("&filterName=")[1].split("&")[0],
        ""
      ); // 處理FilterName
    return url;
  };

  render() {
    let { t } = this.props;
    return (
      <div
        className="personal_lightbox"
        style={{ display: this.state.display }}>
        <div className="overlay" onClick={this.props.close}></div>
        <div className="personalblock">
          <div
            className="close"
            tabIndex="0"
            onClick={this.props.close}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.props.close(e);
              }
            }}></div>
          <h3>{t("jumperrwd.common.relatedSubject")}</h3>
          <ApolloProvider client={client.jumperrwdClient}>
            <Query
              query={searchRelated}
              variables={{
                form: {
                  searchKey: this.props.searchKey ? this.props.searchKey : "",
                  type: "1",
                },
              }}>
              {({ loading, error, data, refetch }) => {
                if (loading)
                  return (
                    <center>
                      <ReactLoading
                        type="cylon"
                        height={"10%"}
                        width={"20%"}
                        color="#005D98"
                      />
                    </center>
                  );
                if (error) return `Error!${error.message}`;

                if (data.searchRelated) {
                  let { cn, en } = data.searchRelated;

                  let cnlist = cn.split(",");
                  let enlist = en.split(",");

                  return (
                    <>
                      <div className="mytags added_tag">
                        <h4>{t("jumperrwd.common.chRelatedSearchWord")}</h4>
                        <ul>
                          <li>
                            {cn === ""
                              ? t("jumperrwd.common.none")
                              : cnlist.map((cn, i) => {
                                  return (
                                    <>
                                      {i !== 0 && `、`}
                                      <Link
                                        href={this.handleUrl(cn)}
                                        target="_BLANK">
                                        {cn}
                                      </Link>
                                    </>
                                  );
                                })}
                          </li>
                        </ul>
                      </div>
                      <div className="mytags other_tag">
                        <h4>{t("jumperrwd.common.enRelatedSearchWord")}</h4>
                        <ul>
                          <li>
                            {en === ""
                              ? t("jumperrwd.common.none")
                              : enlist.map((en, i) => {
                                  return (
                                    <>
                                      {i !== 0 && `、`}
                                      <Link
                                        href={this.handleUrl(en)}
                                        target="_BLANK">
                                        {en}
                                      </Link>
                                    </>
                                  );
                                })}
                          </li>
                        </ul>
                      </div>
                    </>
                  );
                }
                return "";
              }}
            </Query>
          </ApolloProvider>
        </div>
      </div>
    );
  }
}

export default SearchRelatedModal;
